import React from "react";
import { useAppContext } from "../contexts/AppContext";
import ProgressBar from "./ProgressBar";

const Row = ({ keyName, value }) => {
  return (
    <div className="flex flex-row border-b items-center justify-between text-left py-2">
      <p className="w-[45%]">{keyName}</p>
      <p className="w-[45%]">
        <strong>{value}</strong>
      </p>
    </div>
  );
};

export default function ModalAvantages() {
  const { state, updateContext } = useAppContext();
  const close = () => {
    updateContext("modalOpen", false);
  };
  const data = {
    partPremium: {
      "Virement Sortant": "0%",
      "Paiement en devise": "0,10%",
      "Ouverture du compte": "5",
      Parrainage: "5",
      Parrainé: "2,5",
      "Paiements par carte (zone euro)": "0,10%",
      "Paiements par carte (hors zone euro)": "0,30%",
    },
    proPremium: {
      "Virement Sortant": "0,05%",
      "Paiement en devise": "0,10%",
      "Ouverture du compte": "50",
      Parrainage: "25",
      Parrainé: "25",
      "Paiements par carte (zone euro)": "0,60%",
      "Paiements par carte (hors zone euro)": "0,80%",
    },
    partPrestige: {
      "Virement Sortant": "0%",
      "Paiement en devise": "0.10%",
      "Ouverture du compte": "10",
      Parrainage: "10",
      Parrainé: "5",
      "Paiements par carte (zone euro)": "0,15%",
      "Paiements par carte (hors zone euro)": "0,50%",
    },
    proPrestige: {
      "Virement Sortant": "0.05%",
      "Paiement en devise": "0.10%",
      "Ouverture du compte": "80",
      Parrainage: "40",
      Parrainé: "40",
      "Paiements par carte (zone euro)": "1,00%",
      "Paiements par carte (hors zone euro)": "1,30%",
    },
  };

  const getType = (state) => {
    if (
      state.modalData.type === "professionnel" &&
      state.modalData.offre === "prestige"
    )
      return "proPrestige";
    if (
      state.modalData.type === "professionnel" &&
      state.modalData.offre === "premium"
    )
      return "proPremium";
    if (
      state.modalData.type === "particulier" &&
      state.modalData.offre === "premium"
    )
      return "partPremium";
    if (
      state.modalData.type === "particulier" &&
      state.modalData.offre === "prestige"
    )
      return "partPrestige";
    return "error";
  };

  const selectedType = getType(state);
  const selectedData = data[selectedType] || {};

  return (
    <div className="flex flex-col">
      <p className="text-left font-bold uppercase text-[1.5rem]">
        {state.modalData.offre} {state.modalData.type}
      </p>
      <div className="flex flex-col mt-4">
        <div className="flex flex-row border-b items-center justify-between text-left">
          <strong className="w-[45%]">Évènements</strong>
          <strong className="w-[45%]">
            Nombre de points de Fidélité gagnés
          </strong>
        </div>
        {Object.entries(selectedData).map(([key, value]) => (
          <Row key={key} keyName={key} value={value} />
        ))}
        <p className="text-grey-text text-left mt-2">
          Les points sont définitivement acquis après une période de{" "}
          <span className="font-bold">3 mois</span>.
        </p>
      </div>
      <p className="text-left font-semibold text-[1.125rem] mt-8">
        Les points de statut
      </p>
      <p className="text-left text-grey-text mt-2">
        Les points de Statut sont acquis automatiquement pour chaque point de
        Fidélité. Ils permettent d’atteindre différents niveaux (
        <span className="font-bold">Silver</span>,{" "}
        <span className="font-bold">Gold</span>,{" "}
        <span className="font-bold">Diamond</span>) et expirent{" "}
        <span className="font-bold">
          12 mois après la date d’activation du compte
        </span>
        .
      </p>
      <ProgressBar />
      <button
        className="w-full h-[3rem] rounded bg-main-red text-white font-semibold mt-8 lg:w-[12rem] lg:ml-auto"
        onClick={close}
      >
        Fermer
      </button>
    </div>
  );
}
