import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Proximite() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem] pb-16">
        <Retour page="/" section="valeurs" />
        <p className="uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]">
          PROXIMITÉ
        </p>
        <p className="text-left mt-6 font-bold text-[1.125rem]">
        Une relation privilégiée, des réponses immédiates.
        </p>
        <p className="text-left mt-8">Nos conseillers sont là pour vous guider :</p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
          En cas de difficulté, bénéficiez d’une assistance rapide et efficace.
          </li>
          <li className="">
          Avant une décision financière importante, profitez de conseils personnalisés.
          </li>
          <li className="">
          Pour atteindre vos objectifs, recevez un accompagnement sur-mesure adapté à vos besoins.
          </li>
        </ul>

        <p className="text-left mt-6">
        <strong>Avantages :</strong> Des réponses rapides, des recommandations adaptées et un accompagnement complet à chaque étape.
        </p>
       {/*  <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
            <strong>Réponses rapides  :</strong> Vos questions trouvent
            rapidement une réponse, ce qui vous permet de gagner du temps.
          </li>
          <li className="">
            <strong>Conseils personnalisés :</strong> Un conseiller peut vous
            apporter des recommandations adaptées à votre situation personnelle
            et à vos objectifs financiers.
          </li>
          <li className="">
            <strong>Accompagnement dans toutes les démarches  :</strong> Que ce
            soit pour ouvrir un compte, souscrire un crédit ou simplement
            obtenir des informations, un conseiller est là pour vous guider.
          </li>
        </ul> */}
      </div>
      <Footer />
    </>
  );
}
