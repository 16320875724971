import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Exclusivite() {
  return (
    <>
      <div className="py-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem]">
        <Retour page="/" section="valeurs" />
        <p className="uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]">
          EXCLUSIVITÉ
        </p>
        <p className="text-left mt-6 font-bold text-[1.125rem]">
        Des services uniques pour une expérience incomparable.
        </p>
        <p className="text-left font-bold mt-6">Accédez à des privilèges réservés à nos membres :</p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
          Une application personnalisée et intuitive.
          </li>
          <li className="">
          Des récompenses adaptées à votre style de vie grâce à vos points de fidélité.
          </li>
          <li className="">
          Des services de conciergerie pour vos démarches quotidiennes et vos réservations exclusives.
          </li>
          <li className="">
          Des avantages uniques chez nos partenaires : marques de luxe, hôtels et restaurants prestigieux.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
