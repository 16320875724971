import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import useIsMobile from '../hooks/useIsMobile';
export default function HomeVideo() {
  const isMobile = useIsMobile();
  return (
    <div className="container-snap relative h-screen font-poppins">
      {/* Vidéo en arrière-plan */}
      <img
            className="absolute left-0 w-full h-[100%] object-cover z-0"
            /* src="./assets/images/fondParticulier.png" */
            src={isMobile ? "./assets/images/mobile-top-section.png" : "./assets/images/top-section.png"}
          />
        
      {/* Contenu superposé */}
      <div className="relative h-full z-10 flex  lg:h-full px-[1.5rem] flex-col justify-start space-y-8 lg:justify-end lg:pb-[5rem] lg:pl-[4rem] pt-[5.5rem] lg:pt-[10rem] xx:pt-[15rem]">
        <div className='flex flex-col justify-start'>
            <h1 className='w-full text-[1.5rem] lg:text-[3rem] lg:w-[50rem] text-white font-poppins text-left font-semibold'>Votre partenaire unique pour une gestion<span className='text-main-red'> d’exception.</span></h1>
            <Link target='_blank' to='https://app.b-partner.com/register' className='h-fit w-fit'>
            <button className="h-[3rem] w-[11.875rem] bg-main-red text-white font-dmSans font-bold rounded md:hidden mt-[2rem]">
                Ouvrir un compte
            </button>
            </Link>
        </div>
        <p className='text-left font-poppins text-white lg:text-[1.1875rem] lg:mt-8 '>Un accompagnement sur mesure, pour une expérience inégalée.</p>
        <div className='flex flex-col justify-start lg:w-[50rem] '>
            <p className='w-[80%] md:w-full text-[#fff] lg:text-[1.1875rem] font-poppins text-left'>Particulier ou un professionnel,<br className={`${!isMobile && "hidden"}`}/> notre plateforme facilite vos transactions, centralise vos devises et vous fait profiter de services haut de gamme.</p>
        </div>
      </div>
    </div>
  );
}
