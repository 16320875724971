import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Activate() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] lg:pb-16 xx:pl-[8rem]">
        <Retour page="/bPartnerFidelite" section="fidelite" />
        <p className="text-[1.5rem] font-bold text-left uppercase mt-8 lg:text-[2.25rem]">
          Activez votre compte B Partner
        </p>
        <p className="text-left mt-6 font-bold">Activez votre compte, gagnez vos premiers avantages.</p>
        <p className="text-left mt-8">
          
Dès l’activation de votre compte, commencez à cumuler vos points de fidélité. Optez pour un abonnement annuel et doublez vos points dès le départ. Profitez d’un maximum de récompenses et explorez tout le potentiel offert par votre compte B Partner.
        </p>
        <div className="flex flex-col w-full space-y-2 py-8 lg:w-4/5 lg:ml-12">
          <div className="w-full border-b text-grey-text flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Offre choisie</p>
            <p className="w-1/3 text-left">Abonnement mensuel</p>
            <p className="w-1/3 text-left">Abonnement annuel</p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Premium</p>
            <p className="w-1/3 text-left">
              <strong>10 pts</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>20 pts</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Premium business</p>
            <p className="w-1/3 text-left">
              <strong>non disponible</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>60 pts</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Prestige</p>
            <p className="w-1/3 text-left">
              <strong>20 pts</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>40 pts</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Prestige business</p>
            <p className="w-1/3 text-left">
              <strong>non disponible</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>100 pts</strong>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
