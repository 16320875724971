import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function AchatCarte() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] lg:pb-16 xx:pl-[8rem]">
        <Retour page="/bPartnerFidelite" section="fidelite" />
        <p className="text-[1.5rem] font-bold text-left uppercase mt-8 lg:text-[2.25rem]">
          UTILISEZ VOTRE CARTE DE PAIEMENT B PARTNER
        </p>
        <p className="text-left font-bold mt-6">Transformez vos achats en récompenses.</p>
        <p className="text-left mt-8">   
Chaque transaction avec votre carte B Partner vous rapporte des points de fidélité, sans limite de plafond. Vos achats, en ligne ou chez vos commerçants, deviennent une opportunité de cumuler des avantages. Maximisez vos récompenses et profitez d’une carte conçue pour s’adapter à votre style de vie.
        </p>
        <div className="flex flex-col w-full space-y-2 py-8 lg:w-4/5 lg:ml-12">
          <div className="w-full border-b text-grey-text flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Offre</p>
            <p className="w-1/3 text-left">Zone Euro</p>
            <p className="w-1/3 text-left">Hors zone Euro</p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Premium</p>
            <p className="w-1/3 text-left">
              <strong>0,10%</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>0,75%</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Premium business</p>
            <p className="w-1/3 text-left">
              <strong>0,35%</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>1,00%</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Prestige</p>
            <p className="w-1/3 text-left">
              <strong>0,15%</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>1,00%</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-2 items-center">
            <p className="w-1/3 text-left">Prestige business</p>
            <p className="w-1/3 text-left">
              <strong>0,40%</strong>
            </p>
            <p className="w-1/3 text-left">
              <strong>1,30%</strong>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
