// src/pages/Home.js
import React from "react";
import HomeVideo from "../components/HomeVideo";
import Section from "../components/Section";
import useIsMobile from "../hooks/useIsMobile";
import SectionOffers from "../components/SectionOffers";
import Carrousel from "../components/Carroussel";
import Card from "../components/Card";
import Footer from "../components/Footer";
export default function Home() {
  const isMobile = useIsMobile();

  return (
    <div
      className={`flex flex-col ${!isMobile ? "h-auto overflow-hidden" : ""}`}
    >
      <div>
        <HomeVideo />

        <Section
          id="bpClubSection"
          title="B partner fidélité"
          subTitle={<p>Dépensez intelligemment, bénéficiez d’avantages exclusifs. Accumulez du cashback à chaque transaction.</p>}
          text={<p>Transformez vos dépenses en opportunités grâce à des avantages pensés pour enrichir votre expérience.<br/><br/>
            Dès l’activation de votre compte, vous êtes accueilli par une récompense exclusive. Vous bénéficiez ensuite de remises sur une sélection premium de produits et services partenaires, <strong>tout en accumulant du cashback sur chacune de vos transactions.</strong> Une manière unique de valoriser vos achats.</p>}
          bulletPoints={[
          ]}
          imgName="section1-home.png"
          altImg="woman checking her account"
          align="left"
          goTo="/bPartnerFidelite"
          from="/#bpClubSection"
        />

        <Section
          id="conciergerie"
          title="Notre service conciergerie"
          subTitle={<>Un service dédié, pour un quotidien sans limites.</>}
          text={<span>Une assistance sur-mesure, disponible à tout moment pour simplifier et sublimer votre quotidien. Qu’il s’agisse de réserver une table d’exception, d’organiser un voyage ou de simplifier vos démarches administratives, notre équipe est à votre disposition 7j/7. Avec une réactivité garantie sous 2 heures, vos rêves deviennent réalité."</span>}
          bulletPoints={[
          ]}
          imgName="conciergerie.png"
          altImg="man crossed arms"
          align="right"
          goTo="/conciergerie"
          from="/#conciergerie"
          subText=""
        />

        <Section
          id="paiementMultiDevise"
          title="Paiements multi-devises"
          subTitle="La liberté de payer partout dans le monde."
          text="Une solution simple et flexible pour gérer vos transactions internationales sans contraintes. 
Effectuez des paiements dans plus de 50 devises rapidement et en toute sécurité. Avec un compte unique, centralisez toutes vos dépenses et simplifiez vos démarches. "
          bulletPoints={[
          ]}
          imgName="section3-home.png"
          altImg="man checking his account"
          align="left"
          goTo="/devises"
          from="/#paiementMultiDevise"
          subText="Profitez également d’une transparence totale sur les frais et de taux compétitifs pour une expérience fluide et maîtrisée."
        />

        <Section
          id="tpe"
          title="Les Terminaux de paiement"
          subTitle="Des solutions de paiement pensées pour l’excellence."
          text="Pensés pour répondre aux besoins des entreprises modernes et de toutes tailles, nos terminaux combinent technologies performantes, sécurité renforcée et des taux ultra compétitifs. 
Nos terminaux proposent des modes de paiement diversifiés pour répondre aux nouvelles attentes des clients, tout en offrant une autonomie prolongée et une conception robuste."
          bulletPoints={[
          ]}
          imgName="section4-home.png"
          altImg="payment machine"
          align="right"
          goTo="/tpe"
          from="/#tpe"
          imgCssRules="object-contain"
        />

        <Section
          id="sectionReputation"
          title="Service e-réputation"
          subTitle="Valorisez votre image, inspirez la confiance."
          text="Un service dédié à renforcer votre réputation et à protéger votre image en ligne. 
Grâce à une évaluation personnalisée* réalisée par nos experts, identifiez les forces et axes d’amélioration de votre e-réputation."
          bulletPoints={[

          ]}
          imgName="section5-home.png"
          altImg="woman checking her account"
          align="left"
          goTo="/eReputation"
          from="/#sectionReputation"
          subText={<span><span className="italic">Bilan offert*</span><br/>
Ce service exclusif est réservé aux offres Prestige.</span>}
        />

        <SectionOffers />
        <div
          id="valeurs"
          className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14 lg:mt-20 mb-12"
        >
          <p className="uppercase font-bold text-[1.5rem] lg:text-[2.5rem]">
            Nos Valeurs
          </p>
          <p className="font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem]">
          Vos fonds restent disponibles à tout moment, <span className="text-main-red">sans réinvestissement.</span> 
          </p>
          <p className="font-semibold lg:text-[1.125rem] mt-8">
          La disponibilité de vos fonds est notre priorité absolue. Nous ne les réinvestissons jamais, afin de garantir un accès immédiat à vos ressources.
          </p>
          <p className="mt-[1.5rem] text-dark-grey">
          Une philosophie centrée sur la confiance, la transparence et l’exclusivité.
          </p>
          <p className="text-dark-grey">
            Nos <span className="font-bold">certifications ISO</span>  garantissent une sécurité optimale et des services fiables pour toutes vos données. 
          </p>
          <div className="xx:w-fit xx:mx-auto mb-8">
            <Carrousel>
              <Card
                img="./assets/images/ethique-new.png"
                alt={"woman shaking hand"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Ethique
                  </p>
                }
                text={
                  "B Partner protège vos fonds, vos données et votre pouvoir d’achat en adoptant des mesures claires et transparentes."
                }
                goTo={"/ethique"}
                from="/#valeurs"
              />
              <Card
                img="./assets/images/proximite-new.png"
                alt={"carte de crédit b partner"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Proximité
                  </p>
                }
                text={"Une relation privilégiée, des réponses immédiates."}
                goTo={"/proximite"}
                from="/#valeurs"
              />
              <Card
                img="./assets/images/tranquilite-new.png"
                alt={"people talking"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Tranquillité
                  </p>
                }
                text={
                  "Depuis votre smartphone ou ordinateur, gérez facilement vos opérations : virements, consultation de comptes, paiements et suivi de budget."
                }
                goTo={"/tranquillite"}
                from="/#valeurs"
              />
              <Card
                img="./assets/images/exclusivite-new.png"
                alt={"man behind car"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Exclusivité
                  </p>
                }
                text={"Des services uniques pour une expérience incomparable."}
                goTo={"/exclusivite"}
                from="/#valeurs"
              />
            </Carrousel>
          </div>
        </div>
        <div
          className={`${
            isMobile ? "" : "container-snap"
          } hidden flex flex-col font-poppins px-[1.5rem] lg:justify-center lg:pt-[2.5rem] mt-[2.5rem] mb-[2.5rem]`}
        >
          <p className="w-full text-main-red uppercase font-bold tracking-[0.15rem] text-center font-poppins leading-relaxed lg:mt-[2rem]">
            Pour rester informé de toute l’actualité B PArtner
          </p>
          <p className="text-[1.5rem] font-bold leading-relaxed mt-[1.5rem] lg:text-[2.5rem]">
            Inscrivez-vous à notre newsletter
          </p>
          <p className="w-full mt-[1rem] lg:w-[48rem] lg:mx-auto">
            Soyez les premiers à découvrir nos offres exclusives, les dernières
            actualités, des invitations à des événements spéciaux, et des
            opportunités réservées à notre clientèle.
          </p>
          <div className="relative mt-[2.12rem] lg:mt-[1rem] w-fit lg:mx-auto">
            <input
              type="text"
              className="h-[3.75rem] w-full border-b font-poppins pl-4 lg:w-[27.81rem]"
              placeholder="Adresse mail"
            />
            <img
              src="./assets/svg/arrow-right.svg"
              alt="right arrow"
              className="border p-2 rounded-full absolute right-4 top-3 cursor-pointer"
            />
          </div>
          <div className="mt-8 lg:mt-4">
            <p className="text-center text-[1.5rem] font-bold lg:text-[2.5rem]">
              Télécharger l'application
            </p>
            <p className="text-center mt-[1rem]">
              Simplifiez votre vie et accédez rapidement à vos comptes.
            </p>
            <div className="border border-main-red w-[11.6rem] h-[11.6rem] mx-auto flex justify-center items-center rounded-xl mt-[2rem]">
              <img
                alt="qr code"
                src="./assets/images/qr-code.png"
                className="w-[7.31rem] h-[7.31rem]"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
