import React, { useEffect, useMemo, useState } from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
import DynamicSection from "../components/TPE/DynamicSection";
import { useAppContext } from "../contexts/AppContext";
import HubspotForm from "../components/HubspotForm.tsx";
import SectionTpe from "../components/SectionTpe.tsx";
import useIsMobile from "../hooks/useIsMobile.js";
import SimulateTpeCost from "../components/SimulateTpeCost.js";
import Carrousel from "../components/Carroussel.js";
import { constants } from "../constants/index.ts";
import TpeProduct from "../components/TpeProduct.tsx";
import { useTpeContext } from "../contexts/TpeContext.js";
import { Link } from "react-router-dom";

const PRODUCTS = [
  {
    productName: "N86",
    img: "./assets/images/tpe-pro-bgc.png",
    description:
      "Terminal de paiement robuste à longue autonomie avec impression du ticket",
    mainFeatures: [
      "Impression papier",
      "10 rouleaux thermiques offerts",
      "Livraison offerte",
      "Carte Sim illimitée incluse",
    ],
    technicalSpecs: [
      "Puce, Sans Contact et Piste",
      "1GB RAM + 8GB ROM",
      '5.0" HD',
      "5200 mAh",
    ],
  },
  {
    productName: "N62",
    img: "./assets/images/tpe-flex-bgc.png",
    description:
      "Terminal de paiement léger et compact, idéal pour la mobilité sans impression",
    mainFeatures: [
      "Léger et flexible",
      "Envoie de tickets électroniqes",
      "Livraison offerte",
      "Carte Sim illimitée incluse",
    ],
    technicalSpecs: [
      "Puce, Sans Contact",
      "1GB RAM + 8GB ROM",
      '4.0" HD',
      "2000 mAh",
    ],
  },
  {
    productName: "A920 PRO",
    img: "./assets/images/tpe-blast-bgc.png",
    description:
      "Terminal de paiement Android avec grand écran HD et impression rapide",
    mainFeatures: [
      "Impression papier",
      "10 rouleaux thermiques offerts",
      "Livraison offerte",
      "Carte Sim illimitée incluse",
    ],
    technicalSpecs: [
      "Puce, Sans Contact et Piste",
      "1GB RAM + 8GB ROM",
      '5.5" HD',
      "5150 mAh",
    ],
  },
];
const OFFER = "Prestige Business";

export default function Tpe({ isMobileMenuOpen }) {
  const { updateContext } = useAppContext();
  const { products } = useTpeContext();
  const isMobile = useIsMobile();

  const productSheets = useMemo(() => {
    const newProductSheets = [];
    let filterProducts = [];
    if (products.length) {
      for (const product of PRODUCTS) {
        filterProducts = products.filter(
          (filterProduct) =>
            filterProduct.reference_terminal === product.productName &&
            filterProduct.Product === OFFER
        );
        const newProductSheet = {
          img: product.img,
          product: filterProducts[0]?.["TPE Offer"].replace("*", ""),
          description: product.description,
          mainFeatures: product.mainFeatures,
          technicalSpecs: product.technicalSpecs,
          offers: [
            {
              duration: filterProducts[0]?.["Contract Duration (in months)"],
              price: filterProducts[0]?.["Monthly Rental"],
              transactionCost: filterProducts[0]?.["Commission (%)"] + "*",
              offerPeriod: "2 mois offerts",
            },
            {
              duration: filterProducts[1]?.["Contract Duration (in months)"],
              price: filterProducts[1]?.["Monthly Rental"],
              transactionCost: filterProducts[1]?.["Commission (%)"] + "*",
              offerPeriod: "6 mois offerts",
            },
          ],
        };
        newProductSheets.push(newProductSheet);
      }
    }
    return newProductSheets;
  }, [products]);
  const openForm = (formId) => {
    updateContext("modalOpen", true);
    updateContext(
      "modalElement",
      <HubspotForm portalId="144530178" formId={formId} />
    );
  };

  const [isTpeSimulationModalOpen, setIsTpeSimulationModalOpen] =
    useState(false);

  useEffect(() => {
    if (isTpeSimulationModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isTpeSimulationModalOpen]);

  return (
    <>
      {!isMobileMenuOpen && (
        <SimulateTpeCost onClick={() => setIsTpeSimulationModalOpen(true)} />
      )}
      <div className="pt-[10rem] px-[1.5rem] lg:pt-[13.5rem] xx:px-[6rem] font-poppins mb-[5rem]">
        <p className="text-main-red text-left tracking-widest font-semibold">NOS TERMINAUX DE PAIEMENT</p>
        <p className="font-bold text-[1.5rem] lg:text-[2rem] text-left mt-6">Des terminaux performants pour accélérer vos ventes.</p>
        <p className="text-left mt-4">Louez des terminaux adaptés à vos besoins, avec ou sans imprimante et carte SIM intégrée. Notre offre tout-en-un, associée à des coûts de transaction réduits, vous aide à rester compétitif et à protéger vos marges. Une solution fiable, mobile et conçue pour accompagner votre activité partout.</p>
        <p className="uppercase tracking-widest font-semibold text-main-red mt-12">Appareils de paiement connectés</p>

        <div className={`h-10 lg:h-[6rem] items-center hidden`}>
          {isMobile && <div className="bg-black w-full rounded h-0.5" />}
        </div>
        <SectionTpe
          title=""
          costs={["7,99€", "0€", "0,7%"]}
          description={""
          }
          costSubtitle="DES TARIFS DEFIANT TOUTE CONCURRENCE"
        />
        <Carrousel>
          {productSheets.map((productSheet, index) => (
            <TpeProduct key={index} {...productSheet}>
              <Link to={`/pdf?doc=${productSheet.product}.pdf`} target="_blank" className='w-fit h-fit'>
              <button className="h-[3rem] w-full text-white font-bold rounded bg-main-red ">
                En savoir plus
              </button>
              </Link>
            </TpeProduct>
          ))}
        </Carrousel>

        <button
          className="h-[3rem] w-full lg:w-2/5 mt-10 text-white font-bold rounded bg-main-red "
          onClick={() => setIsTpeSimulationModalOpen(true)}
        >
          Simulez le coût de votre TPE
        </button>
      </div>
      
      <DynamicSection
        isOpen={isTpeSimulationModalOpen}
        onClose={() => setIsTpeSimulationModalOpen(false)}
      />
      <div className="flex flex-col px-6 font-poppins lg:px-24">
          <h1 className="uppercase text-left text-[1.5rem] font-bold md:text-[1.75rem] xx:text-[2rem]">terminaux de paiement mobile</h1>
          <div className="flex flex-col lg:flex-row lg:mt-8">
            <img alt='b partner website displayed on mobile' className="w-full mt-8 lg:mt-0 lg:order-2 lg:w-[45%]" src={"https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-tap.png"}/>
            <div className="flex flex-col mt-8 lg:mt-0 lg:order-1 lg:mr-16">
              <h3 className="text-left font-semibold text-[1.125rem]">Transformez votre téléphone en terminal de paiement.</h3>
              <p className="text-left mt-4">Acceptez des paiements par carte directement sur votre téléphone Android grâce à notre application intuitive. Connectée à votre compte B Partner, elle simplifie vos transactions et vous permet de suivre chaque paiement en temps réel, en toute sécurité.</p>
              <Link to={`/pdf?doc=B Partner Smart.pdf`} target="_blank" className='w-full md:w-1/2 mt-8 h-fit lg:w-[15rem]'>
              <button className="h-[3rem] w-full text-white font-bold rounded bg-main-red ">
                En savoir plus
              </button>
              </Link>
            </div>
          </div>
      </div>
      <div className="px-6 mt-8 mb-8 font-poppins lg:mt-12 xl:mt-16">
          <p className="text-main-red uppercase text-[1.125rem] font-semibold tracking-wider">Solution de paiement android</p>
          <h2 className="mt-4 font-bold text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] lg:px-16">Des transactions à très faible coût, sans compromis sur la qualité.</h2>
          <div className="flex flex-row justify-around space-x-2 mt-8 md:space-x-4 md:justify-around lg:mx-auto lg:w-[75%] xl:w-[65%]">
            <div className="w-[7rem] h-fit min-h-[12rem] md:w-[25%] rounded shadow-custom-red flex flex-col items-center py-2 px-1 md:py-4 md:space-y-4 xl:min-h-[15rem]">
              <p className="text-main-red font-bold text-[1.125rem] md:text-[1.75rem] xx:text-[2.5rem]">0€</p>
              <p className="text-[0.875rem] font-bold md:text-[1.125rem] xx:text-[1.5rem]">Coût d'installation</p>
              <p className="text-[0.75rem] md:text-[0.9rem]">Aucun coût pour l'installation de l'application de paiement</p>
            </div>
            <div className="w-[7rem] h-fit min-h-[12rem] rounded shadow-custom-red flex flex-col items-center py-2 px-1 md:w-[25%] md:py-4 md:space-y-4 xl:min-h-[15rem]">
              <p className="text-main-red font-bold text-[1.125rem] md:text-[1.75rem] xx:text-[2.5rem]">0€</p>
              <p className="text-[0.875rem] font-bold md:text-[1.125rem] xx:text-[1.5rem]">Coût de maintenance</p>
              <p className="text-[0.75rem] md:text-[0.9rem]">Aucune facturation pour l’utilisation de l’application</p>
            </div>
            <div className="w-[7rem] h-fit min-h-[12rem] rounded shadow-custom-red flex flex-col items-center py-2 px-1 md:w-[25%] md:py-4 md:space-y-4 xl:min-h-[15rem]">
              <p className="text-main-red font-bold text-[1.125rem] md:text-[1.75rem] xx:text-[2.5rem]">0,7%</p>
              <p className="text-[0.875rem] font-bold md:text-[1.125rem] xx:text-[1.5rem]">Coût de transaction*</p>
              <p className="text-[0.75rem] md:text-[0.9rem]">Un coût de transaction très bas défiant toute concurrence</p>
            </div>
          </div>
          <p className="mt-8 text-[0.75rem] md:text-[0.9rem]">*sur base de 35% de carte de credit et 65% de débit pour des cartes émises dans l’union européenne </p>
      </div>
      
      {/*         <SectionTpe
          title="terminaux de paiement mobile"
          img={
            <img
              alt="smartphone bpartner"
              src="./assets/images/smartphone-bpartner.png"
              className="mt-4 lg:w-[40.25rem] lg:h-[22.81rem] object-cover xx:w-[50.25rem] xx:h-[28rem] lg:mt-0"
            />
          }
          costs={["0€", "0€", "0,8%"]}
          description={
            <>
              <p className="text-left font-semibold text-[1.125rem] mt-2">
                Acceptez des paiements sur votre téléphone c’est possible !
              </p>
              <p className="text-left">
                Vous pouvez désormais accepter des paiements sur votre téléphone
                Android grâce à votre compte B Partner.
              </p>
              <p className="text-left">
                Notre application intuitive, connectée à votre compte,
                transforme votre téléphone en un véritable terminal de paiement.
                Vous pouvez ainsi accepter les paiements par carte de vos
                clients en toute simplicité et sécurité, tout en suivant chaque
                transaction.
              </p>
            </>
          }
          onClick={() => openForm(constants.hubspot.bPartnerTapFormId)}
          buttonText="Souscrire à B Partner Tap"
          costSubtitle="DES COÛTS ULTRA-COMPETITIFS"
        /> */}
      <Footer />
    </>
  );
}
