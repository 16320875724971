import CustomLink from "./CustomLink";

const Card = ({ img, alt, title, text, goTo, from }) => {
  return (
    <CustomLink from={from} to={goTo} className="h-fit w-fit">
      <div className="h-[20rem] shadow-md w-[12.25rem] rounded-xl flex flex-col lg:w-[17rem] lg:h-[21rem] xx:w-[18rem]">
        <img
          className="w-full h-28 rounded-t-xl object-cover lg:min-h-40"
          src={img}
          alt={alt}
        />
        <div className="flex flex-col p-2 justify-start space-y-2 h-full">
          <div className="w-fit h-fit mt-1">{title}</div>
          <p className="text-left text-[0.875rem]">{text}</p>
          <div className="flex items-center !mt-auto cursor-pointer">
            <img src="./assets/svg/chevron-right-red.svg" alt="Chevron" />
            <p className="text-[0.75rem] text-[#8D8F90] ml-2">En savoir plus</p>
          </div>
        </div>
      </div>
    </CustomLink>
  );
};

export default Card;
