import React from "react";
import useIsMobile from "../hooks/useIsMobile";
import Carrousel from "./Carroussel";
import CardBPC from "./CardBPC";
export default function BpcCaroussel() {
  const isMobile = useIsMobile();
  if (isMobile) {
    return (
      <div className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14">
        <p className="uppercase text-main-red tracking-wider font-semibold lg:tracking-widest xx:text-[1.25rem] text-left md:text-center">
          DÉCOUVREZ LES OPPORTUNITÉS B PARTNER CLUB
        </p>
        <p className="font-bold text-[1.25rem] mt-2 lg:text-[1.875rem] uppercase text-left md:text-center" >
          UN ACCÈS VIP AUX ÉVÉNEMENTS
        </p>
        <div className="xx:w-fit xx:mx-auto">
          <Carrousel id="valeurs">
            <CardBPC
              img="./assets/images/bpc-car1.jpeg"
              alt={"dishes"}
              title={<p className="font-bold text-[1rem]">Gastronomie</p>}
              text={
                <p>
                  Accédez aux tables les plus exclusives, là où la haute gastronomie devient une expérience intime. Dîners d’exception dans des restaurants habituellement inaccessibles, moments gourmets inoubliables, conçus pour ravir vos sens.
                </p>
              }
              subText={[
                [
                  "handshake",
                  "Restaurants partenaires : Girafe, Le Piaf, Maxim's, MUN, Louie, Maison Revka, et bien d'autres restaurants d’exception du groupe Paris Society.",
                ],
              ]}
            />
            <CardBPC
              img="./assets/images/bpc-car2.jpeg"
              alt={"prosche"}
              title={
                <p className="font-bold text-[1rem]">Sport & automobile</p>
              }
              text={
                "Vibrez au rythme des plus grands événements sportifs depuis des loges privées. Ressentez l’adrénaline au volant de voitures de luxe sur des circuits de Formule 1, ou partagez des instants uniques lors de galas avec les plus grandes marques automobiles."
              }
              subText={[
                [
                  "handshake",
                  "Partenaires : Ferrari, Porsche, Lamborghini, Bentley, Maserati, McLaren, Aston Martin.",
                ],
                [
                  "ticket",
                  "Accès exclusifs : Matchs de la Ligue des Champions, Rencontres à Roland Garros, Paddock du Grand Prix de Spa-Francorchamps, Ryder Cup, et plus encore.",
                ],
              ]}
            />
            <CardBPC
              img="./assets/images/bpc-car3.jpeg"
              alt={"man on laptop"}
              title={<p className="font-bold text-[1rem]">Voyage & vacances</p>}
              text={
                "Évadez-vous vers des destinations de rêve. Séjours dans des hôtels prestigieux, surclassements garantis et expériences conçues pour transformer chaque voyage en souvenir impérissable."
              }
              subText={[
                [
                  "handshake",
                  "Partenaires : FShangri-La, Mandarin Oriental, Air France-KLM, Club Med, et Center Parcs.",
                ],
              ]}
            />
            <CardBPC
              img="./assets/images/bpc-car4.jpeg"
              alt={"man on laptop"}
              title={
                <p className="font-bold text-[1rem]">Maison & décoration </p>
              }
              text={
                "Explorez les univers créatifs des plus grands designers et architectes lors de prévisualisations exclusives. Accédez à des événements réservés qui allient art, design et innovation."
              }
              subText={[
                [
                  "handshake",
                  "Créateurs de renom : Charles Zana, Dorothée Meilichzon, Sarah Lavoine, Jean Louis Deniot, Didier Gomez.",
                ],
              ]}
            />
            <CardBPC
              img="./assets/images/bpc-car5.jpeg"
              alt={"party"}
              title={<p className="font-bold text-[1rem]">Mode</p>}
              text={
                "Recevez vos invitations pour des défilés, ventes privées et soirées de lancement exclusives. Accompagné par nos Personal Shoppers, vivez la mode comme une expérience unique et personnalisée."
              }
              subText={[
                [
                  "profil",
                  "Un personal shopper vous sera entièrement dédié pour vous accompagner dans vos sorties shopping afin de vous conseiller au mieux.",
                ],
                [
                  "handshake",
                  "Chanel, Louis Vuitton, Hermès, Rolex, Audemars Piguet, et bien d'autres maisons emblématiques.",
                ],
              ]}
            />
            <CardBPC
              img="./assets/images/bpc-car6.jpeg"
              alt={"party"}
              title={<p className="font-bold text-[1rem]">Lifestyle</p>}
              text={
                "Assistez aux concerts les plus prisés depuis les meilleures loges ou accédez à des clubs privés réservés à une élite. Le B Partner Club fait de chaque moment un privilège."
              }
              subText={[
                [
                  "ticket",
                  "Événements et clubs exclusifs : Accor Arena, Paris La Défense Arena, Moulin Rouge, Crazy Horse, Jamel Comedy Club, et les établissements du groupe Paris Society.",
                ],
              ]}
            />
          </Carrousel>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="relative mt-20">
          <p className="uppercase text-main-red tracking-wider font-semibold mt-2 lg:tracking-widest xx:text-[1.25rem]">
            DÉCOUVREZ LES OPPORTUNITÉS B PARTNER CLUB
          </p>
          <p className="font-bold text-[1.25rem] mt-[1.5rem] lg:text-[1.875rem] xx:text-[2.5rem] uppercase">
            UN ACCÈS VIP AUX ÉVÉNEMENTS
          </p>
          <div className="flex flex-row justify-around mt-10 xx:px-[5rem]">
            <CardBPC
              img="./assets/images/bpc-car1.jpeg"
              alt={"dishes"}
              title={<p className="font-bold text-[1.5rem]">Gastronomie</p>}
              text={
                <p>
                  Accédez aux tables les plus exclusives, là où la haute gastronomie devient une expérience intime. Dîners d’exception dans des restaurants habituellement inaccessibles, moments gourmets inoubliables, conçus pour ravir vos sens.
                </p>
              }
              subText={[
                [
                  "handshake",
                  "Restaurants partenaires : Girafe, Le Piaf, Maxim's, MUN, Louie, Maison Revka, et bien d'autres restaurants d’exception du groupe Paris Society.",
                ],
              ]}
            />
            <CardBPC
              img="./assets/images/bpc-car2.jpeg"
              alt={"prosche"}
              title={
                <p className="font-bold text-[1.5rem]">Sport & automobile</p>
              }
              text={
                "Vibrez au rythme des plus grands événements sportifs depuis des loges privées. Ressentez l’adrénaline au volant de voitures de luxe sur des circuits de Formule 1, ou partagez des instants uniques lors de galas avec les plus grandes marques automobiles."
              }
              subText={[
                [
                  "handshake",
                  "Partenaires : Ferrari, Porsche, Lamborghini, Bentley, Maserati, McLaren, Aston Martin.",
                ],
                [
                  "ticket",
                  "Accès exclusifs : Matchs de la Ligue des Champions, Rencontres à Roland Garros, Paddock du Grand Prix de Spa-Francorchamps, Ryder Cup, et plus encore.",
                ],
              ]}
            />
            <CardBPC
              img="./assets/images/bpc-car3.jpeg"
              alt={"man on laptop"}
              title={
                <p className="font-bold text-[1.5rem]">Voyage & vacances</p>
              }
              text={
                "Évadez-vous vers des destinations de rêve. Séjours dans des hôtels prestigieux, surclassements garantis et expériences conçues pour transformer chaque voyage en souvenir impérissable."
              }
              subText={[
                [
                  "handshake",
                  "Partenaires : Shangri-La, Mandarin Oriental, Air France-KLM, Club Med, et Center Parcs.",
                ],
              ]}
            />
          </div>
        </div>
        <div className="relative flex flex-row justify-around mt-14 items-center xx:px-[5rem]">
          <CardBPC
            img="./assets/images/bpc-car4.jpeg"
            alt={"man on laptop"}
            title={
              <p className="font-bold text-[1.5rem]">Maison & décoration </p>
            }
            text={
              "Explorez les univers créatifs des plus grands designers et architectes lors de prévisualisations exclusives. Accédez à des événements réservés qui allient art, design et innovation."
            }
            subText={[
              [
                "handshake",
                "Créateurs de renom : Charles Zana, Dorothée Meilichzon, Sarah Lavoine, Jean Louis Deniot, Didier Gomez.",
              ],
            ]}
          />
          <CardBPC
            img="./assets/images/bpc-car5.jpeg"
            alt={"party"}
            title={<p className="font-bold text-[1.5rem]">Mode</p>}
            text={
              "Recevez vos invitations pour des défilés, ventes privées et soirées de lancement exclusives. Accompagné par nos Personal Shoppers, vivez la mode comme une expérience unique et personnalisée."
            }
            subText={[
              [
                "profil",
                "Un personal shopper vous sera entièrement dédié pour vous accompagner dans vos sorties shopping afin de vous conseiller au mieux.",
              ],
              [
                "handshake",
                "Chanel, Louis Vuitton, Hermès, Rolex, Audemars Piguet, et bien d'autres maisons emblématiques.",
              ],
            ]}
          />
          <CardBPC
            img="./assets/images/bpc-car6.jpeg"
            alt={"party"}
            title={<p className="font-bold text-[1.5rem]">Lifestyle</p>}
            text={
              "Assistez aux concerts les plus prisés depuis les meilleures loges ou accédez à des clubs privés réservés à une élite. Le B Partner Club fait de chaque moment un privilège."
            }
            subText={[
              [
                "ticket",
                "Événements et clubs exclusifs : Accor Arena, Paris La Défense Arena, Moulin Rouge, Crazy Horse, Jamel Comedy Club, et les établissements du groupe Paris Society.",
              ],
            ]}
          />
        </div>
      </>
    );
  }
}
