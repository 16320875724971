import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Tranquilite() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem] pb-16">
        <Retour page="/" section="valeurs" />
        <p className="uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]">
          TRANQUILLITÉ
        </p>
        <p className="text-left mt-6 font-bold text-[1.125rem]">
        Gérez vos finances en toute simplicité.
        </p>
        <p className="text-left mt-8">
        Depuis votre smartphone ou ordinateur, accédez à une gestion fluide de vos opérations : virements, consultation de vos comptes, paiement de factures et suivi de budget.
        </p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
            <strong>Gain de temps :</strong> Des opérations plus rapides et intuitives.
          </li>
          <li className="">
            <strong>Organisation optimale :</strong> Une vue complète sur vos finances.
          </li>
          <li className="">
            <strong>Réduction du stress :</strong> Une gestion sereine, où que vous soyez.
          </li>
          <li className="">
            <strong>Sécurité garantie:</strong> Vos données et vos transactions sont protégées.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
