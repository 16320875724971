import React, { useEffect, useState } from "react";
import { useTpeContext } from "../../contexts/TpeContext";
import { getBestOffer, secondOffer } from "./functions";
import SwitchButton from "../SwitchButton";
import { useAppContext } from "../../contexts/AppContext";
import HubspotForm from "../HubspotForm.tsx";
import PdfSimulation from "./PdfSimulation.tsx";
import { usePDF } from "@react-pdf/renderer";
import { generateTpePdf } from "../../utils/pdf.ts";
import { constants } from "../../constants/index.ts";

export default function Final() {
  const tpe = {
    N86: {
      commercial_name: "B Partner Pro",
      printing: true,
      product_client: "Prestige Business",
      contract_duration: "24 mois",
      terminal_purchase_cost: 0,
      terminal_rental_cost: 10.99,
      maintenance_cost: 0,
      volume_cost: 0.007,
      transaction_cost: 0,
      billing_cost: 3297.0,
      img: "https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-pro.png",
    },
    N62: {
      commercial_name: "B Partner Flex",
      printing: false,
      product_client: "Prestige Business",
      contract_duration: "24 mois",
      terminal_purchase_cost: 0,
      terminal_rental_cost: 7.99,
      maintenance_cost: 0,
      volume_cost: 0.007,
      transaction_cost: 0,
      billing_cost: 2397.0,
      img: "https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-flex.png",
    },
    SoftPOS: {
      commercial_name: "B Partner Tap",
      printing: false,
      product_client: "Prestige Business",
      contract_duration: "24 mois",
      terminal_purchase_cost: 0,
      terminal_rental_cost: 0,
      maintenance_cost: 0,
      volume_cost: 0.007,
      transaction_cost: 0,
      billing_cost: 0.0,
      img: "https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-tap.png",
    },
    "A920 PRO": {
      commercial_name: "B Partner Blast",
      printing: true,
      product_client: "Prestige Business",
      contract_duration: "24 mois",
      terminal_purchase_cost: 0,
      terminal_rental_cost: 14.99,
      maintenance_cost: 0,
      volume_cost: 0.007,
      transaction_cost: 0,
      billing_cost: 0.0,
      img: "https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-blast.png",
    },
  };

  const Row = ({ item, formule, id }) => {
    // Vérifie si l'index est pair pour appliquer un style de fond
    const isEven = (num) => num % 2 === 0;

    return (
      <div
        className={`flex justify-between items-center ${
          isEven(id) ? "bg-[#f5f5f67a]" : ""
        } py-2`}
      >
        <p className="text-[0.875rem] text-left">{item.title}</p>
        {item[formule] === "valid" ? (
          <img alt="valid" src="./assets/svg/valid.svg" />
        ) : (
          <p className="text-sm">{item[formule]}</p>
        )}
      </div>
    );
  };

  const { state, products } = useTpeContext();
  const { updateContext } = useAppContext();
  const handleChoice = (choice) => {
    setChoice(choice);
  };
  const [selectedDuration, setSelectedDuration] = useState("24 mois");
  const [selectedOffer, setSelectedOffer] = useState("Prestige");
  const [choice, setChoice] = useState(
    getBestOffer(
      state.apiResult[selectedOffer][selectedDuration],
      state.userInfo
    )
  );
  const [active, setActive] = useState(true);

  const toggleSection = () => {
    setActive(!active);
  };

  const openForm = () => {
    const formIds = {
      N86: constants.hubspot.bPartnerProFormId,
      N62: constants.hubspot.bPartnerFlexFormId,
      SoftPOS: constants.hubspot.bPartnerTapFormId,
    };
    updateContext("modalOpen", true);
    updateContext("modalElement", <HubspotForm formId={formIds[choice]} />);
  };

  const [instance, updateInstance] = usePDF();

  useEffect(() => {
    const pdfElements = [
      generateTpePdf(
        selectedOffer,
        choice,
        selectedDuration,
        state.apiResult,
        tpe
      ),
    ];
    const filterProducts = products
      .filter((product) => product.reference_terminal === choice)
      .map(({ reference_terminal, characteristics, ...rest }) => rest);
    const indexOfSelectedProduct = filterProducts.findIndex(
      (product) =>
        product["Contract Duration (in months)"] === selectedDuration &&
        product["Product"].includes(selectedOffer)
    );
    updateInstance(
      <PdfSimulation
        pages={pdfElements}
        userInfo={state.userInfo}
        products={filterProducts}
        indexOfSelectedProduct={indexOfSelectedProduct}
      />
    );
  }, [selectedOffer, choice, selectedDuration]);

  const getTabInfos = () => {
    return {
      "Montant mensuel en EURO": state.userInfo.monthly_amount + "€",
      "Nombre de transactions mensuel": state.userInfo.transaction_count,
      "Fonction d’impression du terminal": tpe[choice].printing
        ? "valid"
        : "Non",
      "Coût d’achat du terminal":
        state.apiResult[selectedOffer][selectedDuration].details[choice]
          .terminal_cost + "€",
      "Coût de location du terminal":
        state.apiResult[selectedOffer][selectedDuration].details[choice]
          .rental_cost + "€",
      "Coût de maintenance mensuelle":
        state.apiResult[selectedOffer][selectedDuration].details[choice]
          .maintenance_cost + "€",
      "Carte SIM inclue avec l’appareil": "valid",
    };
  };

  return (
    <div className="flex flex-col">
      <div className="lg:flex lg:flex-row lg:justify-around">
        <div className="lg:w-[20rem] lg:flex lg:flex-col lg:justify-center lg:mb-5">
          <p className="uppercase text-center font-bold text-[1.25rem] lg:text-[2.6rem] lg:text-left">
            Votre offre la plus adaptée
          </p>
        </div>

        <div className="lg:w-[30rem]">
          <div className="flex flex-row w-full mx-auto justify-around mt-4">
            <p
              onClick={() => {
                handleChoice(
                  getBestOffer(
                    state.apiResult[selectedOffer][selectedDuration],
                    state.userInfo
                  )
                );
              }}
              className={`p-3 cursor-pointer ${
                choice ===
                  getBestOffer(
                    state.apiResult[selectedOffer][selectedDuration],
                    state.userInfo
                  ) && "border-2 border-main-red text-main-red rounded"
              } font-semibold`}
            >
              {
                tpe[
                  getBestOffer(
                    state.apiResult[selectedOffer][selectedDuration],
                    state.userInfo
                  )
                ].commercial_name
              }
            </p>
            <p
              onClick={() => {
                handleChoice(
                  secondOffer(
                    state.apiResult[selectedOffer][selectedDuration],
                    state.userInfo
                  )
                );
              }}
              className={`p-3 cursor-pointer ${
                choice ===
                  secondOffer(
                    state.apiResult[selectedOffer][selectedDuration],
                    state.userInfo
                  ) && "border-2 border-main-red text-main-red rounded"
              } font-semibold`}
            >
              {
                tpe[
                  secondOffer(
                    state.apiResult[selectedOffer][selectedDuration],
                    state.userInfo
                  )
                ].commercial_name
              }
            </p>
          </div>

          <div className="flex flex-col justify-center items-center mt-4">
            <img
              alt="tpe"
              src={tpe[choice].img}
              className="w-[11.75rem] h-[11.75rem] object-contain"
            />
            <p className="text-[2.25rem]">
              <span className="text-[0.875rem] text-grey-text">
                à partir de{" "}
              </span>
              {
                state.apiResult[selectedOffer][selectedDuration].details[choice]
                  .rental_cost
              }
              €<span className="text-[0.875rem] text-grey-text">/mois</span>
            </p>
            <span className="text-[0.875rem] text-grey-text">
              sur base annuelle,{" "}
              <strong className="text-main-red">
                offre {selectedOffer} {selectedDuration}
              </strong>
              <br />
              {choice !== "SoftPOS" && "inclus l'offre B Partner Tap"}
            </span>
          </div>
          <div className="flex justify-between space-x-2 my-6">
            <SwitchButton
              value1="Premium"
              value2="Prestige"
              selectedValue={selectedOffer}
              onClick={(value) => setSelectedOffer(value)}
            />
            <div className="lg:my-0 my-3" />
            <SwitchButton
              value1="12 mois"
              value2="24 mois"
              selectedValue={selectedDuration}
              onClick={(value) => setSelectedDuration(value)}
            />
          </div>
          <button
            className="h-[3rem] w-full text-white font-bold rounded bg-main-red lg:mb-6 mb-0"
            onClick={openForm}
          >
            Commander mon Terminal
          </button>
        </div>
      </div>

      <div className="flex flex-row lg:justify-around space-x-2 lg:space-x-0">
        <div className="flex flex-col justify-center items-center w-full lg:h-[14rem] h-40 mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-1">
          <p className="text-main-red lg:text-[3rem] text-2xl font-semibold">
            {parseInt(
              (state.apiResult[selectedOffer][selectedDuration].data_concurrent[
                "Coût annuel Estimé avec acquisition"
              ] -
                state.apiResult[selectedOffer][selectedDuration][choice][
                  "Coût annuel Estimé avec acquisition"
                ]) /
                12
            )}{" "}
            €
          </p>
          <p className="lg:text-[1.125rem] text-base lg:mt-4 mt-2">
            D'économie mensuelle
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full lg:h-[14rem] h-40 mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-2">
          <p className="lg:text-[1.125rem] text-base">Soit une réduction de</p>
          <p className="text-main-red lg:text-[3rem] text-2xl font-semibold lg:mt-4 mt-2">
            {
              state.apiResult[selectedOffer][selectedDuration].Economie?.[
                choice
              ]
            }
          </p>
          <p className="lg:text-[1.125rem] text-base lg:mt-4 mt-2">
            Sur votre facture TPE
          </p>
        </div>
        <div className="flex flex-col justify-center items-center w-full lg:h-[14rem] h-40 mt-8 lg:mt-0 shadow-custom-red lg:w-[16.81rem] lg:h-[14.18rem] lg:px-4 lg:order-3">
          <p className="text-main-red lg:text-[3rem] text-2xl font-semibold">
            {parseInt(
              state.apiResult[selectedOffer][selectedDuration].data_concurrent[
                "Coût annuel Estimé avec acquisition"
              ] -
                state.apiResult[selectedOffer][selectedDuration][choice][
                  "Coût annuel Estimé avec acquisition"
                ]
            )}{" "}
            €
          </p>
          <p className="lg:text-[1.125rem] text-base lg:mt-4 mt-2">
            D'économie annuelle
          </p>
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end mb-2">
          {/* {isMobile && (
            <div
              className="flex items-center px-4 py-2 text-white font-bold rounded bg-main-red mr-2"
              onClick={async () => {
                if (navigator.canShare) {
                  try {
                    await navigator.share({
                      title: "TITLE",
                      text: "TEXT",
                      url: "https://www.google.com",
                    });
                  } catch (err) {
                    console.error("Erreur lors du partage :", err);
                  }
                }
              }}
            >
              <span>Partager</span>
            </div>
          )} */}
          <a
            href={instance.url}
            download="b-partner_simulation-tpe.pdf"
            className="bg-main-red px-4 py-2 flex items-center text-white font-bold rounded"
          >
            <img
              alt="download tpe simulation"
              src="./assets/images/icon-download-white.png"
              className="mr-2 w-5 h-5"
            />
            Télécharger
          </a>
        </div>
        <div
          onClick={toggleSection}
          className="flex flex-row w-full border-b py-2 justify-between cursor-pointer"
        >
          <p className="text-[1.125rem] font-semibold">Détail de l'offre</p>
          <img
            alt="toggle button"
            src={"./assets/svg/" + (active ? "less.svg" : "plus.svg")}
          />
        </div>
        <div className={`${!active && "hidden"} space-y-2`}>
          {Object.entries(getTabInfos()).map(([title, value], index) => (
            <Row
              key={index}
              item={{ title, [choice]: value }}
              formule={choice}
              id={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
