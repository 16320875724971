import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Virements() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] lg:pb-16 xx:pl-[8rem]">
        <Retour page="/bPartnerFidelite" section="fidelite" />
        <p className="text-[1.5rem] font-bold text-left uppercase mt-8 lg:text-[2.25rem]">
          RÉALISEZ DES VIREMENTS SORTANTS
        </p>
        <p className="text-left font-bold mt-6">Des virements simples, rapides et récompensés.</p>
        <p className="text-left mt-8">
        Gérez vos paiements en euros dans toute la zone SEPA, que ce soit pour vos dépenses professionnelles ou pour régler vos fournisseurs. Chaque virement vous permet de cumuler des points de fidélité selon votre statut, vous offrant encore plus d’avantages au quotidien.
        </p>
        <p className="text-left mt-4 text-grey-text">
          *Réservé aux clients professionnels
          <br />
          **Allemagne, Autriche, Andorre, Belgique, Bulgarie, Cité du Vatican
          Chypre, Croatie, Danemark, Espagne, Estonie, Finlande, France,
          Gibraltar, Grèce, Hongrie, Irlande, Islande, Italie, Lettonie,
          Liechtenstein, Lituanie, Luxembourg, Malte, Monaco, Norvège, Pays-Bas,
          Pologne, Portugal, République Tchèque, Roumanie, Royaume-Unis,
          Saint-Martin, Slovaquie, Slovénie, Suède, Suisse
        </p>
        <p className="font-bold text-left text-[1.25rem] mt-8">
          Vous gagnez des points de Fidélité
        </p>
        <div className="flex flex-col w-full space-y-2 py-8 lg:w-4/5 lg:ml-12">
          <div className="w-full border-b text-grey-text flex flex-row space-x-2 items-center justify-center">
            <p className="w-1/2 text-left">Offre choisie</p>
            <p className="w-1/2 text-left">Paiement en devise</p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Premium</p>
            <p className="w-1/2 text-left">
              <strong>0,05%</strong>
            </p>
          </div>
          <div className="w-full border-b text-black flex flex-row space-x-4 items-center">
            <p className="w-1/2 text-left">Premium business</p>
            <p className="w-1/2 text-left">
              <strong>0,05%</strong>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
