import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";

export default function Ereputation() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-[5rem] pb-8">
        <Retour page="/" section="sectionReputation" />
        <div className="lg:flex flex-row lg:h-fit lg:mt-[3rem] xx:mt-[3rem]">
          <div className="text-left lg:w-[30.25rem] xx:w-[42rem] xx:flex xx:flex-col xx:justify-center">
            <p className="mt-4 uppercase font-bold text-[1.5rem] xx:text-[2rem] w-fit">
              SERVICE E-RÉPUTATION
            </p>
            <p className="mt-4 font-bold text-left text-[1.25rem]">Prenez le contrôle de votre image en ligne.</p>
            <p className="mt-4">
            En partenariat avec Social Guard, ce service exclusif évalue votre réputation sur internet et les réseaux sociaux. Inclus dans l’offre Prestige, il vous offre une première analyse gratuite et des recommandations ciblées. Pour une évaluation approfondie, des solutions sur-mesure sont disponibles sur devis.
            </p>
          </div>
          <img
            src="./assets/images/erep-img.png"
            alt="old man checking his b partner account"
            className="w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
