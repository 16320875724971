import React, { PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";
interface TpeProductProps {
  img: string;
  product: string;
  description: string;
  mainFeatures: string[];
  technicalSpecs: string[];
  prices: string[];
  offers: {
    duration: string;
    price: string;
    offerPeriod: string;
    transactionCost: string;
  }[];
}

const TECH_SPECS = ["Lecture", "Mémoire", "Taille de l'éran", "Batterie"];

const TpeProduct = ({
  children,
  img,
  product,
  description,
  mainFeatures,
  technicalSpecs,
  offers,
}: PropsWithChildren<TpeProductProps>) => {
  const getImg = (product : string) => {
    switch (product) {
      case "B Partner Blast":
        return "https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-blast.png"
      case "B Partner Flex ":
        return "https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-flex.png"
      case "B Partner Pro ":
        return "https://b-partner-storage.s3.eu-west-1.amazonaws.com/images/TPE/tpe-pro.png"
      default:
        break;
    }
  }
  const [selectedOffer, setSelectedOffer] = useState(offers[1]);
  return (
    <div className="border border-neutral/gray/gray-60 2xl:w-[30vw] lg:w-[30vw] w-72 lg:p-5 p-3">
      <img alt="product" src={getImg(product)} className="h-72 object-contain w-full" />
      <p className="text-left mt-4 lg:text-2xl text-xl font-semibold">
        Terminal {product}
      </p>
      <p className="mt-2 text-left lg:text-md text-sm lg:min-h-0 min-h-16">
        {description}
      </p>
      <div className="flex flex-row flex-1 space-x-2 mt-3 lg:text-md text-sm">
        {offers.map((offer, index) => (
          <div
            key={index}
            className={`flex-1 border rounded p-3 flex flex-col space-y-2 cursor-pointer ${
              selectedOffer.duration === offer.duration &&
              "border-main-red bg-[#cc133e20]"
            }`}
            onClick={() => setSelectedOffer(offer)}
          >
            <span>{offer.duration}</span>
            <span>
              <strong>{offer.price}</strong>/mois
            </span>
            <span className="text-[#16A34A]">{offer.offerPeriod}</span>
          </div>
        ))}
      </div>
      <div className="bg-gray-100 mt-3 mb-6">
        <div className="flex flex-row justify-between p-2 text-sm">
          <span className="font-extralight">Coût d'achat</span>
          <span>0 €</span>
        </div>
        <div className="flex flex-row justify-between p-2 text-sm">
          <span className="font-extralight">Frais de transaction</span>
          <span>{selectedOffer.transactionCost}</span>
        </div>
        <div className="flex flex-row justify-between p-2 text-sm">
          <span className="font-extralight">Location mensuelle</span>
          <span>{selectedOffer.price}</span>
        </div>
      </div>
      {children}
      <div className="bg-gray-100 p-2 mt-6">
        <span className="text-[#6B7280] text-sm font-extralight">
          Pour toute location d'un terminal B Partner PRO le paiement depuis votre smartphone** est offert
        </span>
      </div>
      <div className="mt-6 space-y-4 lg:text-xs text-[0.6rem]">
        <div className="flex flex-row">
          <div className="flex flex-row flex-1 items-center">
            <img
              alt="delivery"
              src="./assets/svg/delivery.svg"
              className="w-[1.5rem] h-[1.5rem] mr-3"
            />
            <span className="text-left">Livraison 4-5 jours</span>
          </div>
          <div className="flex flex-row flex-1 items-center">
            <img
              alt="calendar"
              src="./assets/svg/calendar.svg"
              className="w-[1.5rem] h-[1.5rem] mr-3"
            />
            <span className="text-left">Garantie 2 ans</span>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-row flex-1 items-center">
            <img
              alt="clock"
              src="./assets/svg/clock-grey.svg"
              className="w-[1.5rem] h-[1.5rem] mr-3"
            />
            <span className="text-left">Installation 10 minutes</span>
          </div>
          <div className="flex flex-row flex-1 items-center">
            <img
              alt="audio"
              src="./assets/svg/audio.svg"
              className="w-[1.5rem] h-[1.5rem] mr-3"
            />
            <span className="text-left">
              Support du lundi au samedi de 8:00 à 20:00
            </span>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:flex-row text-left mt-6">
        <div className="flex-1">
          <p>Caractéristiques principales</p>
          <ul className="text-sm font-extralight mt-2 space-y-1">
            {mainFeatures.map((feat, index) => (
              <li key={index} className="flex flex-row items-center">
                <img
                  alt="chevron-right"
                  src="./assets/svg/chevron-right.svg"
                  className="w-5 h-5"
                />
                {feat}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 lg:mt-0 mt-6">
          <p>Spécifications techniques</p>
          <ul className="text-sm font-extralight mt-2 space-y-1">
            {technicalSpecs.map((spec, index) => (
              <li key={index}>
                <strong>{TECH_SPECS[index]}:</strong> {spec}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="lg:flex lg:flex-row mt-6 items-center">
        <Link to={`/pdf?doc=${product}technical.pdf`} target="_blank" className='w-fit h-fit flex-1 flex flex-row items-center'>
        <div className=" flex flex-row">
          <img
            alt="download"
            src="./assets/svg/download.svg"
            className="w-[1.5rem] h-[1.5rem] mr-3"
          />
          <span className="text-sm text-left">Télécharger la brochure technique</span>
        </div>
        </Link>
        <div className="text-[0.5rem] text-left flex-1 lg:mt-0 mt-3">
          <span>** Solution B Partner Smart disponible sur Android et IOS</span>
          <br />
          <span>
            * sur base de 35% de carte de crédit et 65% de carte de débit
          </span>
        </div>
      </div>
    </div>
  );
};

export default TpeProduct;
