import React from "react";
import useIsMobile from "../hooks/useIsMobile";
import SectionBpc from "../components/SectionBpc";
import BpcCaroussel from "../components/BpcCaroussel";
import Footer from "../components/Footer";
export default function BPartnerClub() {
  const isMobile = useIsMobile();
  return (
    <div
      className={`flex flex-col ${!isMobile ? "h-auto overflow-hidden" : ""}`}
    >
      <div>
        <div className="container-snap relative h-screen">
          {/* Vidéo en arrière-plan */}
          <img
            className="absolute top-0 left-0 w-full h-full object-cover z-0 grayscale"
            alt="b partner club"
            src="./assets/images/homebpc.jpeg"
          />

          {/* Contenu superposé */}
          <div className="relative h-full z-10 flex bg-[#000000d5] lg:h-full px-[1.5rem] flex-col justify-start lg:items-center pt-[25%] lg:pt-0 lg:justify-center">
            <div className="flex flex-col justify-center space-y-5">
              <p className="text-center uppercase font-bold text-main-red tracking-wider lg:text-center lg:text-[1.25rem] xx:text-[1.5rem]">
                B Partner Club
              </p>
              <h1 className="w-full text-[1.75rem] lg:text-[2.5rem] lg:w-fit text-white font-poppins  lg:text-center font-semibold mt-8">
              Un monde hors norme à votre portée.
              </h1>
            </div>
            <p className="text-center text-white text-[0.825rem] lg:text-[1rem] xx:text-[1.125rem] lg:w-1/2 font-poppins mt-8">Découvrez le B Partner Club, un cercle confidentiel réservé à une clientèle d’exception.</p>
            {/* <div className="flex flex-col justify-start lg:w-full lg:pl-[4rem]">
              <h2 className="w-full text-[1.05rem] lg:text-[2rem] text-white font-poppins text-left">
                Un club d’affaires{" "}
                <span className="text-main-red">confidentiel</span>
              </h2>
              <p className="text-left font-poppins text-[0.875rem] leading-[2.25rem] mt-2 text-white lg:text-[1.1875rem]">
                Offrant un accès privilégié à des évènements et expériences
                Uniques et d’Exceptions
              </p>
            </div> */}
          </div>
        </div>
        <SectionBpc
          id="avantages"
          title="DÉCOUVREZ DE NOUVELLES aventures"
          subTitle="Un monde d’expériences inégalées."
          text={
            isMobile ? (
              "Réservé aux membres Prestige et Prestige Business, le B Partner Club répond aux attentes des plus exigeants. Au cœur de ce cercle confidentiel, enrichissez votre réseau avec des clients partageant vos valeurs et accédez à des événements exclusifs en France et en Europe. Chaque expérience, qu’elle soit gastronomique, culturelle ou sportive, est pensée pour offrir l’inaccessible : des moments rares, luxueux et inestimables. Ici, le temps n’a pas de prix, et chaque opportunité devient une expérience à part entière."
            ) : (
              <p>
                Réservé aux membres Prestige et Prestige Business, le B Partner Club répond aux attentes des plus exigeants. Au cœur de ce cercle confidentiel, enrichissez votre réseau avec des clients partageant vos valeurs et accédez à des événements exclusifs en France et en Europe. Chaque expérience, qu’elle soit gastronomique, culturelle ou sportive, est pensée pour offrir l’inaccessible : des moments rares, luxueux et inestimables. Ici, le temps n’a pas de prix, et chaque opportunité devient une expérience à part entière.
              </p>
            )
          }
          bulletPoints={[]}
          imgName="bpc1.jpeg"
          altImg="girl walking by"
          align="left"
          button={false}
        />
        <BpcCaroussel />
        <SectionBpc
          id="avantages"
          title="OUVREZ VOTRE RÉSEAU"
          subTitle="Soirées de gala et networking"
          text={
              <p className="mt-2">
                Renforcez vos opportunités professionnelles au sein de nos soirées de gala exclusives. Conçus pour encourager des échanges de haute qualité, ces événements se déroulent dans des lieux prestigieux et offrent un cadre idéal pour rencontrer des acteurs influents partageant votre vision. Le B Partner Club crée les connexions qui font la différence.
              </p>

          }
          bulletPoints={[]}
          imgName="bpc-img2.jpeg"
          altImg="girl walking by"
          align="right"
          button={false}
        />
        <SectionBpc
          id="avantages"
          title="B Partner club"
          subTitle="Rejoignez l’élite"
          text={
            <>
              <p className="mt-2">
              Bien plus qu’un programme de récompenses, le B Partner Club vous ouvre les portes d’un monde de privilèges uniques. Participez à des événements exclusifs, rencontrez des personnalités influentes et vivez des expériences inestimables, conçues pour sublimer chaque moment. Réservé aux membres Prestige, ce cercle confidentiel incarne l’excellence et l’art de vivre d’exception.
              </p>
              <p className="text-black mt-4">
              <strong>Intéressé à rejoindre le cercle fermé des membres Prestige ?</strong><br/> Utilisez davantage votre carte bancaire au sein de notre réseau de partenaires et faites le premier pas vers des expériences que seul le B Partner Club peut vous offrir.
              </p>
            </>
          }
          bulletPoints={[]}
          imgName="bpc-img3.jpeg"
          altImg="girl walking by"
          align="left"
          button={false}
          goTo="https://app.b-partner.com/register"
          blank={true}
        />
        <div className="mb-12" />
        <Footer />
      </div>
    </div>
  );
}
