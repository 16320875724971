import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Assistant() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] lg:pt-[8rem] xx:pl-[8rem] font-poppins mb-[5rem]">
        <Retour page="/particuliers" section="valeurs" />
        <div className="flex flex-col lg:mt-[2.5rem]">
          <div className="">
            <p className="font-bold text-[1.5rem] w-fit lg:text-[2.25rem] text-left">
              ASSISTANT PERSONNEL LIFESTYLE MANAGEMENT
            </p>
            <div className="mt-4 flex flex-col space-y-4 lg:flex-row lg:space-x-[8rem] lg:items-start lg:space-y-0">
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="clock"
                  src="./assets/svg/clock-red.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p>Sans interruption*</p>
                  <p>7 jours sur 7</p>
                  <p className="text-[0.6rem]">
                    *En français de 9h00 à 18h00, sinon en anglais hors des heures d'ouverture
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit lg:h-full">
                <img
                  alt="phone"
                  src="./assets/svg/phone.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">+33 1 82 28 12 75</p>
                </div>
              </div>
              <div className="flex flex-row items-start w-fit">
                <img
                  alt="help & contact"
                  src="./assets/svg/aide-contact.svg"
                  className="mt-1"
                />
                <div className="flex flex-col text-left ml-3">
                  <p className="">Retour dans un délai de 2h</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center">
              <div className="text-left space-y-4 mt-[2.5rem] lg:mt-0 lg:w-[30.5rem] xx:w-[40rem] ">
                <p className="text-left text-[1.25rem] font-bold font-poppins">Votre quotidien, sublimé par un service sur-mesure.</p>
                <p className="font-bold">
                Disponible à tout moment, l’Assistant Personnel B Partner prend en charge vos demandes les plus simples comme les plus sophistiquées :
                </p>
                <p>
                • Organisation de vos vacances ou d’événements privés.<br/>
                •  Réservations de loges VIP ou suggestions de restaurants prestigieux.<br/>
                •  Accompagnement sur mesure pour vos journées shopping ou vos déplacements.<br/>
                </p>
                <p>
                Chaque détail est pensé pour vous offrir confort, discrétion et excellence.
                </p>
              </div>
              <img
                src="./assets/images/img2-conciergerie.jpeg"
                alt="man on the phone"
                className="w-full h-[18.5rem] object-cover mt-6 lg:w-[40.25rem] lg:h-[22.81rem] lg:ml-auto xx:w-[50rem] xx:h-[25rem] xx:mr-[6rem]"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
