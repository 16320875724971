import React from "react";
import Retour from "../components/Retour";
import Footer from "../components/Footer";
export default function Ethique() {
  return (
    <>
      <div className="pt-[6rem] px-[1.5rem] font-poppins lg:pt-[8rem] xx:pl-[8rem] lg:pb-16 xx:w-4/5 xx:pt-[10rem] pb-16">
        <Retour page="/" section="valeurs" />
        <p className="uppercase font-bold text-[1.5rem] w-fit mt-6 lg:text-[2.25rem]">
          ÉTHIQUE
        </p>
        <p className="mt-8 font-bold text-left text-[1.125rem]">L’éthique au cœur de nos engagements.</p>
        <p className="text-left mt-6">
          <strong>B Partner </strong>a choisi de placer l’éthique au centre de
          ses pratiques, avec un objectif clair : protéger ses clients et leurs
          avoirs. Cette valeur fondamentale nous pousse à mettre en œuvre
          plusieurs mesures :{" "}
        </p>
        <ul className="mt-6 text-left list-disc pl-[1.5rem] space-y-6">
          <li className="">
            <strong>Vos fonds sécurisés :</strong> Ils ne sont jamais réinvestis et restent disponibles à tout moment.
          </li>
          <li className="">
            <strong>Vos données protégées :</strong> Elles ne sont jamais vendues ou partagées, sauf avec nos partenaires privilégiés.
          </li>
          <li className="">
            <strong>Vos coûts maîtrisés :</strong> Une tarification simple, transparente et sans surprise.
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
