import { useState, useEffect } from 'react';

function useScrolledPast(threshold = 80) {
  const [isScrolledPast, setIsScrolledPast] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolledPast(window.pageYOffset > threshold);
    };

    window.addEventListener('scroll', handleScroll);

    // Nettoyage pour éviter les fuites de mémoire
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [threshold]);

  return isScrolledPast;
}

export default useScrolledPast;
